/* eslint-disable react/forbid-component-props */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/forbid-dom-props */
/* eslint-disable no-alert */
import Button from 'components/common/Button';
import { getUnixTime } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoCloseCircleSharp } from 'react-icons/io5';
import { MdAdminPanelSettings } from 'react-icons/md';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { cancelSubscription } from '../../services/subscription.service';
import { AffiliateMain } from '../account/affiliate/AffiliateMain';
import BillingInfo from '../account/BillingInfo';
import Compte from '../account/Compte';
import Header from '../Header';

import { setAccountAreaState } from '@/shared/reducers/accountAreaReducer';
import {
  removeCredential,
  setCredentials,
} from '@/shared/reducers/authReducer';
import { useLazyGetDisconnectShopifyQuery } from '@/shared/slices/shopifySlice';
import { useGetUserSubscriptionQuery } from '@/shared/slices/subscriptionSlice';
import { productionLog } from '@/utils/helpers';

function AccountArea() {
  const { t } = useTranslation('translation');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, token: stateToken } = useSelector((state) => state?.auth);
  const { shopifyDisconnectionModal } = useSelector(
    (state) => state?.accountArea
  );
  const [daysUntilRenewal, setDaysUntilRenewal] = useState(0);
  const [generatedProducts, setGeneratedProducts] = useState(0);
  const [stock, setStock] = useState(0);
  const [regeneratedProducts, setRegeneratedProducts] = useState(0);
  const [isSubscriptionCanceled, setIsSubscriptionCanceled] = useState(false);

  const { data: subscriptionInfo } = useGetUserSubscriptionQuery();
  const [getDisconnectShopify] = useLazyGetDisconnectShopifyQuery();

  const { plan } = user || {};
  const regenerationLimits = {
    free: 0,
    Starter: 30,
    Standard: 200,
    Pro: 500,
    Lifetime: 9999,
  };

  const fetchProducts = async () => {
    try {
      const response = await fetch(
        `${process.env.VITE_APP_API_URL}/products`, // Replace this URL with your backend API URL
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('productly')}`, // Assuming you're using token-based authentication
          },
        }
      );
      const data = await response.json();
      setGeneratedProducts(data.generatedProducts);
      setRegeneratedProducts(data.regeneratedProducts);
      setStock(data.stock);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  useEffect(() => {
    if (!user) return;

    if (
      user?.expiration &&
      user?.subscription_id !== '' &&
      !user.referer_owner
    ) {
      navigate('/upgrade');
    }
    if (user?.renewalDate < getUnixTime(new Date())) {
      dispatch(removeCredential());
      navigate('/login');
    }
    fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, subscriptionInfo]);

  const productLimits = {
    free: 1,
    Starter: 3,
    Standard: 10,
    Pro: 50,
    Lifetime: 9999,
  };

  const maxProductsForPlan = (userPlan) => stock ?? productLimits[userPlan];

  const maxRegenerationsForPlan = (userPlan) =>
    regenerationLimits[userPlan] || 0;

  const progressRegBarWidth = (current, max) => (current / max) * 100;

  const progressBarWidth = () => {
    if (user && plan) {
      // Add the user's stock to the maximum number of products for the plan
      const maxProducts = maxProductsForPlan(plan);

      const progress = ((generatedProducts ?? 0) / maxProducts) * 100;
      return progress > 100 ? 100 : progress;
    }
    return 0;
  };

  useEffect(() => {
    if (
      !subscriptionInfo ||
      !subscriptionInfo?.renewalDate ||
      !subscriptionInfo?.status
    ) {
      return;
    }

    const renewalDateInSeconds = parseInt(subscriptionInfo.renewalDate, 10);
    // Check if the renewal date is in seconds and convert to milliseconds
    const renewalDate = new Date(renewalDateInSeconds * 1000);
    const currentDate = new Date();
    const timeDiff = renewalDate - currentDate;
    setDaysUntilRenewal(Math.ceil(timeDiff / (1000 * 60 * 60 * 24))); // convert from milliseconds to days
    const subscriptionStatus = [
      'incomplete',
      'incomplete_expired',
      'past_due',
      'canceled',
      'unpaid',
    ];
    setIsSubscriptionCanceled(
      subscriptionStatus.includes(subscriptionInfo?.status)
    );
  }, [subscriptionInfo, isSubscriptionCanceled]);

  /* Function to handle password change */
  const handlePasswordChange = () => {
    navigate('/change-password');
  };

  const deleteSubscription = async () => {
    const isConfirmed = window.confirm(t('account.subscriptionDelete'));
    if (!isConfirmed) return;
    try {
      const response = await cancelSubscription(user?.id);
      if (response && response?.message) {
        toast.success(response?.message);
      }
      const tokenResponse = await fetch(
        `${process.env.VITE_APP_API_URL}/generate-new-token`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('productly')}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (tokenResponse.status !== 200) {
        throw new Error('Failed to generate a new token');
      }
      const { token } = await tokenResponse.json();
      dispatch(setCredentials({ token }));
      setIsSubscriptionCanceled(true);
    } catch (error) {
      toast.error(t('subscriptionDeleteError'));
    }
  };

  const closeDisconnectModal = () => {
    dispatch(
      setAccountAreaState({
        shopifyDisconnectionModal: false,
      })
    );
  };

  const disconnectShopify = async () => {
    closeDisconnectModal();
    try {
      const disconnectShopifyResponse = await getDisconnectShopify().unwrap();
      if (!disconnectShopifyResponse?.success) {
        toast.error(t('account.shopifyDisconnectedFailed'));
        return;
      }
      toast.success(t('account.shopifyDisconnectedSuccess'));
      navigate('/app');
    } catch (error) {
      toast.error(t('account.shopifyDisconnectedFailed'));
    }
  };

  productionLog(user);

  return (
    <div>
      <div className="absolute w-full h-full xs:h-[850px] pointer-events-none md:bg-account-gradient-background xs:bg-account-gradient-background-xs" />
      <Header />
      {user ? (
        <div className="mt-10 container mx-auto">
          <div className="text-white text-[12px] border border-solid rounded-lg border-grayLight md:grid md:grid-cols-2">
            <div className="xs:p-6 sm:p-16">
              <Compte
                user={user}
                generatedProducts={generatedProducts}
                regeneratedProducts={regeneratedProducts}
                stock={stock}
                maxProductsForPlan={maxProductsForPlan}
                maxRegenerationsForPlan={maxRegenerationsForPlan}
                progressBarWidth={progressBarWidth}
                progressRegBarWidth={progressRegBarWidth}
                handlePasswordChange={handlePasswordChange}
              />
              <div className="my-5">
                <div className="font-semibold">
                  {!!user?.dashboard && (
                    <>
                      <h3 className="mt-1 font-semibold text-white">
                        Admin Dashboard
                      </h3>
                      <Button
                        type="button"
                        className="w-full p-3 mt-2 font-bold text-white border-0 rounded-md bg-primary"
                        onClick={() => {
                          window.location.href = `https://dashboard.productly.app/auth?token=${stateToken}`;
                        }}
                        text="Access admin dashboard"
                        icon={<MdAdminPanelSettings className="ml-1" />}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="xs:p-6 sm:p-16">
              <BillingInfo
                subscriptionInfo={subscriptionInfo}
                isSubscriptionCanceled={isSubscriptionCanceled}
                daysUntilRenewal={daysUntilRenewal}
                deleteSubscription={deleteSubscription}
              />
            </div>
            <Modal
              className="modal-container w-[450px]"
              isOpen={shopifyDisconnectionModal}
              overlayClassName="modal-overlay"
              onRequestClose={closeDisconnectModal}
            >
              <div className="rounded-lg md:min-w-full text-sm lg:text-base w-46 p-6 px-10 bg-white relative">
                <Button
                  text=""
                  className="bg-transparent border-0 outline-none !justify-end absolute top-1 right-0"
                  icon={<IoCloseCircleSharp className="text-3xl" />}
                  onClick={closeDisconnectModal}
                />
                <div className="flex flex-col items-center">
                  <h4 className="m-0 mb-2 text-defaultBlack/70">
                    {t('account.confirmDisconnectModal')}
                  </h4>
                </div>
                <div className="flex justify-center items-center">
                  <Button
                    type="button"
                    className="py-2 h-fit px-4 mt-2 me-2 text-sm border-0 rounded-md text-defaultBlackLight/70 hover:bg-primary hover:text-white"
                    text={t('common.confirm')}
                    onClick={disconnectShopify}
                  />
                  <Button
                    type="button"
                    className="py-2 h-fit px-4 mt-2 text-sm text-white border-0 rounded-md bg-primary"
                    text={t('common.close')}
                    onClick={closeDisconnectModal}
                  />
                </div>
              </div>
            </Modal>
          </div>
          {user?.referer_owner && <AffiliateMain />}
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
}

export default AccountArea;
