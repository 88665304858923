/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/no-danger */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCircleCheck } from 'react-icons/fa6';
import { HiColorSwatch } from 'react-icons/hi';
import { IoMdAdd } from 'react-icons/io';
import { IoChevronDown } from 'react-icons/io5';
import { MdEdit } from 'react-icons/md';
import { TbEyeShare } from 'react-icons/tb';
import { useSelector } from 'react-redux';

import ImportProductCallToAction from './ImportProductCallToAction';
import ImportProductFaq from './ImportProductFaq';
import ImportProductImages from './ImportProductImages';
import ImportProductInfo from './ImportProductInfo';
import ImportProductLongBenefits from './ImportProductLongBenefits';
import ImportProductReviews from './ImportProductReviews';

import Button from '@/components/common/Button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/common/DropdownMenu';
import { RootState } from '@/shared/reduxStore';
import {
  useGetShopifyTemplateDataQuery,
  useImportShopifyTemplateMutation,
  useUpdateShopifyTemplateDataMutation,
} from '@/shared/slices/shopifySlice';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { PlansLevel } from '@/shared/types/user';

export interface FormFieldsSettingProps {
  id: string;
  name: string;
  text: string;
  title?: string;
  image?: string;
  textClass?: string;
  inputType?: string;
  editFieldClass?: string;
  displayBtnClass?: string;
  editable?: boolean;
}

function ImportTemplate() {
  const { t } = useTranslation();
  const { currentProductId } = useSelector(
    (state: RootState) => state?.products
  );
  const { user } = useSelector((state: RootState) => state?.auth);
  const [importTemplateData, { isLoading: postingToShopify }] =
    useImportShopifyTemplateMutation();
  const { data: shopifyTemplateData, isFetching: gettingShopifyTemplateData } =
    useGetShopifyTemplateDataQuery(
      {
        productId: currentProductId,
        homepage: 0,
      },
      {
        skip: !currentProductId,
      }
    );

  const [updateTemplateData] = useUpdateShopifyTemplateDataMutation();
  const [productInfoFields, setProductInfoFields] = useState<
    FormFieldsSettingProps[]
  >([]);

  const updateTemplateSettings = ({ name, content }: any) =>
    setProductInfoFields((prevField) =>
      prevField.map((field) => {
        const copyOfField = field;
        if (field.name === name) {
          copyOfField.text = content;
        }
        return copyOfField;
      })
    );

  const onInputChange = async ({ value: content, id, name }: any) => {
    if (!content || !name) return;

    // If no setting id meaning this input changes is for regeneration
    if (!id) {
      updateTemplateSettings({ name, content }); // { title, content }
      return;
    }

    let updatePayload: Record<string, any> = { content };
    if (name.includes('Title')) {
      updatePayload = { title: content };
    }
    if (name.includes('longBenefitsImage')) {
      updatePayload = { image: content };
    }

    await updateTemplateData({
      id,
      name,
      homepage: 1,
      ...updatePayload,
    });
    updateTemplateSettings({ name, content });
  };

  const handleTemplateImport = () => {
    importTemplateData({
      productId: Number(currentProductId),
    });
  };

  useEffect(() => {
    if (!shopifyTemplateData || !shopifyTemplateData?.shortBenefits) return;
    const {
      totalReviews,
      shortBenefits,
      longBenefits,
      description,
      mainReview,
      reviews,
      productName,
      faqs,
      callToAction,
    } = shopifyTemplateData;
    const isUserPlanStillValid = user?.expiration !== true;
    setProductInfoFields([
      {
        id: totalReviews?.id ?? 0,
        name: 'totalReviews',
        text: totalReviews?.content ?? '',
        textClass: 'text-sm',
        editable: isUserPlanStillValid,
      },
      {
        id: productName?.id ?? 0,
        name: 'productName',
        text: productName?.content ?? '',
        dataname: productName?.id ?? 0,
        textClass: 'text-2xl font-bold',
        editable: isUserPlanStillValid,
      },
      {
        id: description?.id ?? 0,
        name: 'productDescription',
        text: description?.content ?? '',
        textClass: 'mb-2 font-semibold',
        editFieldClass: 'min-h-[100px] h-fit',
        inputType: 'textarea',
        editable: isUserPlanStillValid,
      },
      {
        id: mainReview?.id ?? 0,
        name: 'mainReview',
        text: mainReview?.content ?? '',
        textClass: 'mb-2 mt-3 text-xs italic leading-5',
        editFieldClass: 'min-h-[150px] h-fit',
        inputType: 'textarea',
        editable: isUserPlanStillValid,
      },
      {
        id: shortBenefits?.id ?? 0,
        name: `shortBenefits`,
        text: shortBenefits?.content ?? '',
        textClass: 'text-sm',
        displayBtnClass: 'block',
        inputType: 'contentEditable',
        editFieldClass: 'min-h-[150px] h-fit',
        editable: isUserPlanStillValid,
      },
      ...longBenefits.map((benefit: Record<string, any>, index: number) => ({
        id: benefit?.id ?? 0,
        name: `longBenefitsTitle${index}`,
        text: benefit?.title ?? '',
        textClass:
          'self-start text-[18px] font-semibold text-bluePrimary md:text-3xl',
        editFieldClass: 'w-full',
        editable: isUserPlanStillValid,
      })),
      ...longBenefits.map((benefit: Record<string, any>, index: number) => ({
        id: benefit?.id ?? 0,
        name: `longBenefitsContent${index}`,
        text: benefit?.content ?? '',
        textClass: 'text-md self-start leading-8 font-normal',
        editFieldClass: 'min-h-[200px] h-fit',
        inputType: 'textarea',
        editable: isUserPlanStillValid,
      })),
      ...longBenefits.map((benefit: Record<string, any>, index: number) => ({
        id: benefit?.id ?? 0,
        name: `longBenefitsImage${index}`,
        text: benefit?.image ?? '',
        textClass: 'text-md self-start leading-8 font-normal',
        editFieldClass: 'min-h-[200px] h-fit',
        inputType: 'textarea',
        editable: isUserPlanStillValid,
      })),
      ...reviews.map((review: Record<string, any>, index: number) => ({
        id: review?.id ?? 0,
        name: `review${index}`,
        text: review?.content ?? '',
        textClass: 'text-center text-xs font-normal tracking-tight sm:text-sm',
        editFieldClass: 'min-h-[100px] h-fit bg-white',
        inputType: 'textarea',
        editable: isUserPlanStillValid,
      })),
      ...faqs.map((faq: Record<string, any>, index: number) => ({
        id: faq?.id ?? 0,
        name: `faqTitle${index}`,
        text: faq?.title ?? '',
        textClass: 'self-start text-sm font-semibold',
        editFieldClass: 'w-full',
        editable: isUserPlanStillValid,
      })),
      ...faqs.map((faq: Record<string, any>, index: number) => ({
        id: faq?.id ?? 0,
        name: `faqContent${index}`,
        text: faq?.content ?? '',
        textClass: 'text-center text-xs font-normal tracking-tight sm:text-sm',
        editFieldClass: 'min-h-[100px] h-fit bg-white',
        inputType: 'textarea',
        editable: isUserPlanStillValid,
      })),
      {
        id: callToAction?.id ?? 0,
        name: `callToActionTitle`,
        text: callToAction?.title ?? '',
        textClass:
          'text-center text-[28px] font-bold leading-[40px] tracking-tight',
        editFieldClass: 'w-full',
        editable: isUserPlanStillValid,
      },
      {
        id: callToAction?.id ?? 0,
        name: `callToActionContent`,
        text: callToAction?.content ?? '',
        textClass:
          'mt-6 text-center text-[14px] font-normal leading-[40px] tracking-tight sm:text-[18px]',
        editFieldClass: 'w-full',
        inputType: 'textarea',
        editable: isUserPlanStillValid,
      },
    ]);
  }, [shopifyTemplateData, user]);

  if (!shopifyTemplateData || gettingShopifyTemplateData) {
    return <p>{t('shopify.loading')}</p>;
  }

  const { editTemplateURL, editProductURL, viewURL } =
    shopifyTemplateData?.productOnShopify ?? {};

  const childProps = {
    fields: productInfoFields,
    onInputChange,
  };

  const isUserPaid =
    (PlansLevel[user.plan] as unknown as number) >= PlansLevel.Pro;
  const isUserPlanStillValid = user?.expiration !== true;
  return (
    <div className="flex items-center w-full justify-center">
      <div className="z-10 m-2 w-full flex flex-col items-center rounded-[20px] bg-white text-defaultBlack bg-clip-border p-4 max-w-[800px]">
        {editTemplateURL && isUserPaid && isUserPlanStillValid && (
          <div className="w-full flex justify-end space-x-2 border-b border-0 border-solid border-black/10 pb-4 box-border">
            <div className="xs:block lg:flex justify-between w-full">
              <div className="flex items-center">
                <FaCircleCheck className="text-success mr-2 text-xl" />
                <p className="text-sm xs:text-xs">
                  {t('shopify.importToShopifySuccess')}
                </p>
              </div>
              {postingToShopify ? (
                <Button
                  text={t('shopify.importToShopify')}
                  className="outline-none px-4 py-2 border-0 rounded-md text-white text-sm shadow-md shadow-black/5"
                  icon={<IoMdAdd className="text-md" />}
                  iconPosition="before"
                  loading
                  loadingText={`${t('shopify.importToShopify')}...`}
                />
              ) : (
                <DropdownMenu>
                  <DropdownMenuTrigger className="outline-none px-4 py-2 bg-white border border-solid border-black/20 rounded-md text-defaultBlack text-sm flex items-center shadow-md shadow-black/5">
                    {t('shopify.moreActionBtn')}{' '}
                    <IoChevronDown className="ml-2" />
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="w-56 border border-solid border-black/10">
                    <DropdownMenuLabel>
                      {t('shopify.shopifyActionBtn')}
                    </DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    <DropdownMenuGroup>
                      <DropdownMenuItem
                        className="hover:!text-primary hover:cursor-pointer"
                        onClick={handleTemplateImport}
                      >
                        <IoMdAdd className="mr-2" />
                        {t('shopify.importAgainBtn')}
                      </DropdownMenuItem>
                      <a
                        href={editTemplateURL}
                        className="!text-defaultBlack no-underline "
                        target="_blank"
                        rel="noreferrer"
                      >
                        <DropdownMenuItem className="hover:!text-primary hover:cursor-pointer">
                          <HiColorSwatch className="mr-2" />
                          {t('shopify.editTemplateBtn')}
                        </DropdownMenuItem>
                      </a>
                      <a
                        href={editProductURL}
                        className="!text-defaultBlack no-underline"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <DropdownMenuItem className="hover:!text-primary hover:cursor-pointer">
                          <MdEdit className="mr-2" />
                          {t('shopify.editProductBtn')}
                        </DropdownMenuItem>
                      </a>
                      <a
                        href={viewURL}
                        className="!text-defaultBlack no-underline"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <DropdownMenuItem className="hover:!text-primary hover:cursor-pointer">
                          <TbEyeShare className="mr-2" />
                          {t('shopify.viewProductBtn')}
                        </DropdownMenuItem>
                      </a>
                    </DropdownMenuGroup>
                  </DropdownMenuContent>
                </DropdownMenu>
              )}
            </div>
          </div>
        )}

        <div className="mt-3 h-full w-full grid-cols-12 gap-10 pt-6 xs:flex xs:flex-col sm:grid">
          <ImportProductImages />
          <ImportProductInfo {...childProps} />
        </div>

        <ImportProductLongBenefits {...childProps} />
        <ImportProductFaq {...childProps} />
        <ImportProductReviews {...childProps} />
        <ImportProductCallToAction {...childProps} />
      </div>
    </div>
  );
}

export default ImportTemplate;
