import find from 'lodash/find';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaTruckFast } from 'react-icons/fa6';
import { TbTruckReturn } from 'react-icons/tb';
import { VscVerified, VscVerifiedFilled } from 'react-icons/vsc';
import { useSelector } from 'react-redux';

import Paypal from '@/assets/shopify/paypal.svg';
import RatingStars from '@/components/common/RatingStars';
import { Button } from '@/components/common/shadcn-ui/Button';
import TextLoadingSkeleton from '@/components/common/TextLoadingSkeleton';
import EditableText from '@/components/EditableText';
import { RootState } from '@/shared/reduxStore';
import { useGetShopifyTemplateDataQuery } from '@/shared/slices/shopifySlice';

interface ImportProductInfoProps {
  fields: Record<string, any>[];
  onInputChange: ({ value, id, name }: any) => void;
}

function ImportProductInfo({ fields, onInputChange }: ImportProductInfoProps) {
  const { t } = useTranslation();
  const { currentProductId, shopifyHomepageImportTemplate } = useSelector(
    (state: RootState) => state?.products
  );
  const { data: shopifyTemplateData, isFetching: gettingShopifyTemplateData } =
    useGetShopifyTemplateDataQuery(
      {
        productId: currentProductId,
        homepage: shopifyHomepageImportTemplate ? 1 : 0,
      },
      {
        skip: !currentProductId,
      }
    );

  const productInfoFields = fields.filter(({ name }) =>
    ['productName', 'productDescription', 'shortBenefits'].includes(name)
  );
  const mainReviewField: any = find(fields, { name: 'mainReview' });
  const totalReviewsField: any = find(fields, { name: 'totalReviews' });
  const { mainReview } = shopifyTemplateData ?? {};
  return (
    <div className="col-span-12 w-full xl:col-span-6">
      <div className="w-auto rounded-xl bg-white px-2 py-3">
        <div className="flex w-full items-center justify-start gap-1 mb-4">
          <RatingStars />
          <div className="ml-2">
            <EditableText
              {...totalReviewsField}
              onInputChange={onInputChange}
            />
          </div>
        </div>
        <div className="space-y-4">
          {productInfoFields.map((field: any) => (
            <EditableText
              {...field}
              onInputChange={onInputChange}
              key={field.id}
            />
          ))}
        </div>
      </div>

      <Button
        type="button"
        className="linear bg-white p-6 w-full rounded-none cursor-pointer text-based outline-none border border-solid border-black shadow-none font-normal tracking-widest text-defaultBlack hover:bg-white"
      >
        {t('shopify.shopifyAddToCart')}
      </Button>
      <Button
        type="button"
        className="linear bg-[#ffc520] p-6 mt-2 w-full rounded-none cursor-pointer text-based outline-none border-0 shadow-none font-normal tracking-widest text-defaultBlack hover:bg-[#ffc520]"
      >
        {t('shopify.shopifyByWith')}
        <img src={Paypal} className="h-4 ml-1" alt="" />
      </Button>
      <Button
        type="button"
        className="linear bg-transparent p-6 my-2 w-full rounded-none cursor-pointer text-xs underline underline-offset-4 outline-none border-0 shadow-none font-normal tracking-widest text-defaultBlack hover:bg-transparent"
      >
        {t('shopify.morePaymentOptions')}
      </Button>
      <div className="flex items-center justify-between">
        <div className="flex flex-col items-center justify-center">
          <FaTruckFast className="w-7 h-7" />
          <p className="pt-3 text-center text-xs text-bluePrimary">
            {t('shopify.freeTrackedInsuredShipping')}
          </p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <TbTruckReturn className="w-7 h-7" />
          <p className="pt-3 text-center text-xs text-bluePrimary">
            {t('shopify.freeReturns')}
          </p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <VscVerified className="w-7 h-7" />
          <p className="pt-3 text-center text-xs text-bluePrimary">
            {t('shopify.moneyBack')}
          </p>
        </div>
      </div>

      <div className="my-6 h-[1px] w-full bg-slate-100" />

      <div className="flex  items-center gap-1 pb-10 text-center sm:flex-row sm:text-left">
        <div className="w-full">
          <div className="flex w-full items-center justify-center gap-0.5 sm:justify-start">
            <RatingStars starClass="max-w-[12px]" />
          </div>
          {gettingShopifyTemplateData && (
            <TextLoadingSkeleton className="bg-defaultBlack" />
          )}
          {mainReview && (
            <>
              <EditableText
                {...mainReviewField}
                onInputChange={onInputChange}
              />
              <p className="relative text-xs font-bold leading-5">
                <VscVerifiedFilled className="absolute mr-2 inline-block size-4 text-primary" />
                <span className="pl-5">{mainReview.author}</span>
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ImportProductInfo;
