/* eslint-disable no-nested-ternary */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Button from '../common/Button';

import { RootState } from '@/shared/rootReducers';

interface facturationDataProps {
  subscriptionInfo: any;
  isSubscriptionCanceled: boolean;
  daysUntilRenewal: number;
  deleteSubscription: () => void;
}

function BillingInfo({
  subscriptionInfo,
  isSubscriptionCanceled,
  daysUntilRenewal,
  deleteSubscription,
}: facturationDataProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'account' });
  const navigate = useNavigate();
  const { currency } = useSelector((state: RootState) => state?.plan);

  const { plan, price, isAnnual, card } = subscriptionInfo ?? {};
  const isLifetimePlan = plan === 'Lifetime';
  const subCancelledText = isSubscriptionCanceled
    ? t('subscriptionCanceledTrue')
    : t('subscriptionCanceledFalse');
  const annualText = isAnnual ? t('an') : t('mois');
  const pricePerPeriod = price ? (price / 100).toFixed(2) : 0;
  const priceText = `${pricePerPeriod}${currency === 'eur' ? '€' : '$'} / ${annualText}`;
  return (
    <div className="flex flex-col">
      {/* Title */}
      <div className="relative pb-1 text-white">
        <h1 className="after:content-[''] after:absolute after:bottom-0 after:left-[-15px] after:bg-gradient-to-r after:right-0 after:h-[1px] after:from-transparent after:from-[0%] after:via-[rgba(255,255,255,0.2)] after:via-[20%] after:to-transparent after:to-[100%]">
          {t('billingHeader')}
        </h1>
      </div>
      {/* Current plan */}
      <div className="mt-5 font-semibold">
        <h3 className="text-[16px]">{t('planEnCours')}</h3>
      </div>
      {/* Card with Pyament Informatin */}
      <div className="pb-7 flex flex-col px-4 py-3 font-semibold rounded-lg border-solid border-[rgba(255,255,255,0.15)] bg-gradient-to-r from-[#8628C0] to-[#A238D5]">
        <span className="text-[12px] mb-4">Productly {plan ?? 'Free'}</span>
        {!isLifetimePlan && (
          <span className="text-[20px] font-bold mb-4">{priceText}</span>
        )}
        <span className="text-[10px] mb-2">
          {isLifetimePlan
            ? t('neverExpire')
            : `${subCancelledText} ${daysUntilRenewal} ${t('jours')}*`}
        </span>
        <div>
          {card ? (
            <span className="text-[9px] font-normal">
              {t('subscriptionInfo1')} {subscriptionInfo.card.last4}
            </span>
          ) : (
            <span className="text-[9px] font-normal">
              {t('subscriptionInfo2')}
            </span>
          )}
        </div>
      </div>
      {/* Subscriptions buttons */}
      {!isLifetimePlan && (
        <>
          <div className="mt-5">
            <Button
              type="button"
              className="w-full p-3 mt-2 font-bold text-black bg-white border-0 rounded-md"
              text={t('chargerDePlan')}
              onClick={() => navigate('/upgrade')}
            />
          </div>
          {!isSubscriptionCanceled && subscriptionInfo && (
            <Button
              type="button"
              className="w-full p-3 mt-5 font-bold text-white bg-black border-[1px] rounded-md"
              onClick={deleteSubscription}
              text={t('subscriptionCanceled')}
            />
          )}
        </>
      )}
    </div>
  );
}

export default BillingInfo;
