import { uniqueId } from 'lodash';
import find from 'lodash/find';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { FormFieldsSettingProps } from '.';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/common/shadcn-ui/Accordion';
import EditableText from '@/components/EditableText';
import { RootState } from '@/shared/reduxStore';
import { useGetShopifyTemplateDataQuery } from '@/shared/slices/shopifySlice';

interface ImportProductFaqProps {
  fields: Record<string, any>[];
  onInputChange: ({ value, id, name }: any) => void;
}

function ImportProductFaq({ fields, onInputChange }: ImportProductFaqProps) {
  const { t } = useTranslation();
  const { currentProductId, shopifyHomepageImportTemplate } = useSelector(
    (state: RootState) => state?.products
  );
  const { data: shopifyTemplateData } = useGetShopifyTemplateDataQuery(
    {
      productId: currentProductId,
      homepage: shopifyHomepageImportTemplate ? 1 : 0,
    },
    {
      skip: !currentProductId,
    }
  );
  const { faqs } = shopifyTemplateData ?? {};
  return (
    <div className="mt-20 flex max-w-[300px] flex-col items-center justify-center sm:max-w-[760px]">
      <p className="text-center text-[28px] font-bold leading-[40px] tracking-tight">
        {t('shopify.faq')}
      </p>
      <Accordion type="single" collapsible className="w-[500px]">
        {faqs?.map(({ title }: any, index: number) => {
          const titleField = find(fields, {
            name: `faqTitle${index}`,
          }) as FormFieldsSettingProps;
          const contentField = find(fields, {
            name: `faqContent${index}`,
          }) as FormFieldsSettingProps;
          return (
            <AccordionItem value={title} key={uniqueId('faq')}>
              <AccordionTrigger className="bg-transparent border-0 border-t border-solid hover:bg-defaultBlack/5 text-left">
                <EditableText {...titleField} onInputChange={onInputChange} />
              </AccordionTrigger>
              <AccordionContent>
                <EditableText {...contentField} onInputChange={onInputChange} />
              </AccordionContent>
            </AccordionItem>
          );
        })}
      </Accordion>
    </div>
  );
}

export default ImportProductFaq;
