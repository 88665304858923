/* eslint-disable jsx-a11y/label-has-associated-control */
import { HighlightOff } from '@material-ui/icons';
import clsx from 'clsx';
import Button from 'components/common/Button';
import _findIndex from 'lodash/findIndex';
import _last from 'lodash/last';
import React, { useState, useEffect, useRef } from 'react';
import '../css/subscriptions.css';
import { useTranslation } from 'react-i18next';
import { IoIosInfinite } from 'react-icons/io';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import PlanItem from './PlanItem';

import { Badge } from '../common/Badge';
import { useFeaturesHook } from '../hooks/useFeaturesHook';
import { useUpgradePlan } from '../hooks/useUpgradePlan';

import Animation from '@/components/common/Animation';
import { cn } from '@/lib/utils';
import { subscriptionPlans } from '@/shared/configs/subscriptionPlans';
import { currencySign } from '@/shared/currency';
import { removeCredential } from '@/shared/reducers/authReducer';
import { RootState } from '@/shared/rootReducers';
import { PlansLevel } from '@/shared/types/user';

function Subscriptions() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const features = useFeaturesHook();
  const { user } = useSelector((state: RootState) => state?.auth);
  const { currency } = useSelector((state: RootState) => state?.plan);
  const [isAnnualButton, setIsAnnualButton] = useState(false);
  const [isAnnualAccount, setIsAnnualAccount] = useState(false);

  const [handleUpgradePlan, { isLoading: checkoutLoading }] = useUpgradePlan();
  const anualCheckboxRef = useRef<HTMLInputElement>(null);
  const location = useLocation(); // Get the current location

  const { subscriptionDuration } = user || {};

  const { t } = useTranslation('translation', { keyPrefix: 'upgrade' });

  useEffect(() => {
    if (!user) return;
    if (subscriptionDuration >= 365) {
      setIsAnnualAccount(true);
    } else {
      setIsAnnualAccount(false);
    }
  }, [subscriptionDuration, user]);

  const handleLogout = () => {
    dispatch(removeCredential());
    navigate('/login');
  };

  const userPlanLevel = PlansLevel[user?.plan] as unknown as number;

  const normalPlans = subscriptionPlans.filter(
    (plan) => plan.name !== 'Lifetime'
  );
  const lifeTimePlan: Record<string, any> = _last(subscriptionPlans) ?? {};
  const isLifetimeDisable = userPlanLevel >= PlansLevel.Lifetime;
  return (
    <Animation
      className="relative flex flex-col items-center container py-28"
      id="prix"
    >
      <>
        <h2 className="free-trial-heading">
          {t('tryTheTool')}
          <span className="text-[#a238d5] inline-block relative ml-2">
            {t('free')}
          </span>
          !
        </h2>
        <div className="toggle-container">
          <span className="billing-cycle">{t('monthly')}</span>
          <label className="toggle">
            <input
              type="checkbox"
              onChange={() =>
                setIsAnnualButton(anualCheckboxRef.current?.checked ?? false)
              }
              ref={anualCheckboxRef}
            />
            <span className="slider" />
          </label>
          <span className="billing-cycle">{t('annual')}</span>
        </div>
        <div className="flex items-center justify-between w-full sm:justify-around lg:w-full xs:flex-col sm:flex-row sm:flex-wrap lg:flex-nowrap gap-5 lg:justify-center">
          {normalPlans?.map((plan, index) => (
            <PlanItem
              key={plan.name}
              isAnnualAccount={isAnnualAccount}
              isAnnualButton={isAnnualButton}
              planIndex={index}
              plan={plan}
              handleUpgradePlan={handleUpgradePlan}
              checkoutLoading={checkoutLoading}
            />
          ))}
        </div>
        {/* Lifetime plan */}
        <div
          className={cn(
            'mt-8 w-full lg:max-w-[900px] bg-defaultBlackLight lg:rounded-2xl xs:rounded-lg text-white',
            isLifetimeDisable && 'opacity-50'
          )}
        >
          <div className="flex xs:flex-col lg:flex-row xs:items-center p-8">
            <div className="leading-tight lg:mr-5 w-fit">
              <Badge className="rounded-full">{t('lifetimeOffer')}</Badge>
              <h1 className="font-medium m-0 mt-3 wr">{t('lifetimePro')}</h1>
              <h1 className="font-medium m-0">
                {currency
                  ? lifeTimePlan?.discountPrice?.[currency]
                  : lifeTimePlan?.discountPrice.usd}
                {currency ? currencySign?.[currency] : '$'}
              </h1>
              <h1 className="font-medium text-2xl line-through m-0 text-zinc-500">
                {currency
                  ? lifeTimePlan?.originalPrice?.[currency]
                  : lifeTimePlan?.originalPrice?.usd}
                {currency ? currencySign?.[currency] : '$'}
              </h1>
            </div>
            <div className="grid grid-rows-5 lg:grid-flow-col lg:w-fit sm:w-5/12 xs:gap-3 lg:gap-x-4 lg:gap-y-2">
              {features?.map((feature, fIndex) => (
                <div className="flex items-center w-full">
                  <IoCheckmarkCircleOutline className="mr-2 text-success w-[24px] h-[24px]" />
                  {lifeTimePlan?.features[fIndex] === 9999 && (
                    <IoIosInfinite className="w-[24px] h-fit mr-2" />
                  )}
                  {feature}
                </div>
              ))}
            </div>
          </div>
          <div className="w-full text-center px-8 pb-8 box-border">
            <p className="mb-3 text-xs text-amber-300">
              <i>{t('lifetimeNoTrial')}</i>
            </p>
            <Button
              type="button"
              className={clsx(
                'w-full bg-primary text-base outline-none border-0 text-white py-2 rounded-lg',
                (isLifetimeDisable || checkoutLoading) &&
                  '!bg-none !shadow-none !bg-[#353535] !cursor-default'
              )}
              onClick={() =>
                handleUpgradePlan({
                  planName: 'Lifetime',
                  isPlanDisabled: isLifetimeDisable,
                  currency,
                })
              }
              disabled={checkoutLoading}
              text={isLifetimeDisable ? t('unavailable') : t('upgrade')}
            />
          </div>
        </div>
        {user && location.pathname === '/upgrade' && (
          <Button
            type="button"
            className="modify-pass-button upgrade"
            onClick={handleLogout}
            text={t('signOut')}
            icon={<HighlightOff className="ml-1" />}
          />
        )}
      </>
    </Animation>
  );
}

export default Subscriptions;
