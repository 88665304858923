import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

type AccountAreaState = {
  shopifyDisconnectionModal?: boolean;
};

const initialState: AccountAreaState = {
  shopifyDisconnectionModal: false,
};

const accountAreaSlice = createSlice({
  name: 'accountArea',
  initialState,
  reducers: {
    setAccountAreaState: (
      state,
      { payload }: PayloadAction<AccountAreaState>
    ) => ({
      ...state,
      ...payload,
    }),
    resettAccountAreaState: () => initialState,
  },
});

export const { setAccountAreaState, resettAccountAreaState } =
  accountAreaSlice.actions;

export default accountAreaSlice.reducer;
