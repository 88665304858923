import find from 'lodash/find';
import React from 'react';

import { FormFieldsSettingProps } from '.';

import EditableText from '@/components/EditableText';

interface ImportProductFaqProps {
  fields: Record<string, any>[];
  onInputChange: ({ value, id, name }: any) => void;
}

function ImportBanner({ fields, onInputChange }: ImportProductFaqProps) {
  const bannerField = find(fields, {
    name: `bannerBenefit`,
  }) as FormFieldsSettingProps;
  return (
    <div className="w-full flex items-center justify-center h-[300px] bg-[url('/public/shopify/default-banner.svg')] bg-cover bg-center">
      <h3 className="bg-white mx-28 p-2 rounded-lg">
        <EditableText {...bannerField} onInputChange={onInputChange} />
      </h3>
    </div>
  );
}

export default ImportBanner;
