import { apiSlice } from './apiSlice';

const subscriptionSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getUserSubscription: build.query<any, void>({
      query: () => ({
        url: `subscription/user`,
        method: 'GET',
      }),
    }),
    getUserCurrency: build.query<any, void>({
      query: () => ({
        url: `stripe/currency`,
        method: 'GET',
      }),
    }),
    checkout: build.mutation<any, any>({
      query: (checkoutData) => ({
        url: 'stripe/create-checkout-session',
        method: 'POST',
        body: checkoutData,
      }),
    }),
  }),
});

// eslint-disable-next-line import/prefer-default-export
export const {
  useGetUserSubscriptionQuery,
  useCheckoutMutation,
  useLazyGetUserCurrencyQuery,
} = subscriptionSlice;
