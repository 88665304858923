import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import accountEN from './src/locales/en/account.json';
import applicationEN from './src/locales/en/application.json';
import authEN from './src/locales/en/auth.json';
import commonEN from './src/locales/en/common.json';
import homeEN from './src/locales/en/home.json';
import memberAreaEN from './src/locales/en/memberArea.json';
import productEN from './src/locales/en/product.json';
import scratchEN from './src/locales/en/scratch.json';
import shopifyEN from './src/locales/en/shopify.json';
import upgradeEN from './src/locales/en/upgrade.json';
import accountFR from './src/locales/fr/account.json';
import applicationFR from './src/locales/fr/application.json';
import authFR from './src/locales/fr/auth.json';
import commonFR from './src/locales/fr/common.json';
import homeFR from './src/locales/fr/home.json';
import memberAreaFR from './src/locales/fr/memberArea.json';
import productFR from './src/locales/fr/product.json';
import scratchFR from './src/locales/fr/scratch.json';
import shopifyFR from './src/locales/fr/shopify.json';
import upgradeFR from './src/locales/fr/upgrade.json';

// the translations
const resources = {
  en: {
    translation: {
      common: commonEN,
      auth: authEN,
      upgrade: upgradeEN,
      scratch: scratchEN,
      memberArea: memberAreaEN,
      home: homeEN,
      account: accountEN,
      shopify: shopifyEN,
      application: applicationEN,
      product: productEN,
    },
  },
  fr: {
    translation: {
      common: commonFR,
      auth: authFR,
      upgrade: upgradeFR,
      scratch: scratchFR,
      memberArea: memberAreaFR,
      home: homeFR,
      account: accountFR,
      shopify: shopifyFR,
      application: applicationFR,
      product: productFR,
    },
  },
};

const DETECTION_OPTIONS = {
  order: ['localStorage', 'navigator'],
  caches: ['localStorage'],
};

const languagePrefer = localStorage.getItem('language_prefer') ?? null;
const languageDefault = localStorage.getItem('i18nextLng') ?? 'fr';
i18n
  .use(initReactI18next)
  .use(LanguageDetector) // passes i18n down to react-i18next
  .init({
    lng: languagePrefer ?? languageDefault,
    fallbackLng: 'fr',
    debug: false,
    defaultNS: 'translation',
    resources,
    detection: DETECTION_OPTIONS,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
