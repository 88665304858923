import { apiSlice } from './apiSlice';

export interface GenerateContentResponse {
  success?: boolean;
}

export interface ImportToShopifyProps {
  productId: number | string;
  title: string;
  descriptionHtml: string;
  productType?: string;
  vendor?: string;
  tags?: string;
}

export interface UpdateShopifyTemplateDataProps {
  id: number;
  name?: string;
  content?: string;
  title?: string;
  image?: string;
  homepage?: number;
}

export interface RegenerateShopifyTemplateDataProps {
  id: number;
  productId: number;
  type: string;
  homepage?: number;
}

export interface ImportShopifyTemplateProps {
  productId: number;
  homepage?: number;
}

export interface ShopifyConnectProps {
  shopifyUrl: string;
  shopifyCode: string;
}

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ['SHOPIFY_TEMPLATE_DATA'],
});

const shopifySlice = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    importProductToShopify: build.mutation<any, ImportToShopifyProps>({
      query: (shopifyData) => ({
        url: 'shopify/product/add',
        method: 'POST',
        body: shopifyData,
      }),
    }),
    shopifyConnect: build.mutation<any, ShopifyConnectProps>({
      query: (connectData) => ({
        url: 'shopify/connect',
        method: 'POST',
        body: connectData,
      }),
    }),
    getShopifyData: build.query<any, { productId: any }>({
      query: ({ productId }) => ({
        url: `shopify/data/${productId}`,
        method: 'GET',
      }),
    }),
    getShopifyTemplateData: build.query<
      any,
      { productId: any; homepage?: number }
    >({
      query: ({ productId, homepage = 0 }) => ({
        url: `shopify/template/data?id=${productId}&homepage=${homepage}`,
        method: 'GET',
      }),
      providesTags: ['SHOPIFY_TEMPLATE_DATA'],
    }),
    regenerateShopifyTemplateData: build.mutation<
      any,
      RegenerateShopifyTemplateDataProps
    >({
      query: (templateData) => ({
        url: 'shopify/template/regenerate',
        method: 'POST',
        body: templateData,
      }),
    }),
    updateShopifyTemplateData: build.mutation<
      any,
      UpdateShopifyTemplateDataProps
    >({
      query: (templateData) => ({
        url: 'shopify/template/update',
        method: 'POST',
        body: templateData,
      }),
    }),
    importShopifyTemplate: build.mutation<any, ImportShopifyTemplateProps>({
      query: (templateData) => ({
        url: 'shopify/template/import',
        method: 'POST',
        body: templateData,
      }),
      invalidatesTags: ['SHOPIFY_TEMPLATE_DATA'],
    }),
    getDisconnectShopify: build.query<any, void>({
      query: () => ({
        url: 'shopify/cancelShopify',
        method: 'GET',
      }),
    }),
  }),
});

// eslint-disable-next-line import/prefer-default-export
export const {
  useImportProductToShopifyMutation,
  useGetShopifyDataQuery,
  useLazyGetShopifyDataQuery,
  useShopifyConnectMutation,
  useGetShopifyTemplateDataQuery,
  useLazyGetShopifyTemplateDataQuery,
  useUpdateShopifyTemplateDataMutation,
  useImportShopifyTemplateMutation,
  useLazyGetDisconnectShopifyQuery,
  useRegenerateShopifyTemplateDataMutation,
} = shopifySlice;
